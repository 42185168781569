import React from 'react';

//  Icons
import logoWhite from '../assets/atoz_logos/logo_white.png';
import longLogoWhite from '../assets/atoz_logos/long_logo_white.png';
import facebookIcon from '../assets/facebook.png';
import instagramIcon from '../assets/instagram.png';
import whatsappIcon from '../assets/whatsapp.png';
import designRush from '../assets/badge.png';
import phoneIcon from '../assets/phone.png';
import { NavLink } from 'react-router-dom';

const Footer = () => {
  const links = [
    {
      link: '/',
      text: 'Home',
    },
    {
      link: '/services',
      text: 'Services',
    },
    {
      link: '/about-us',
      text: 'About Us',
    },
  ];

  const renderLinks = () => {
    return links.map((link, index) => {
      const activeStyle =
        window.location.pathname === link.link
          ? 'text-[#DAA520] font-extrabold '
          : '';
      return (
        <li key={index}>
          <NavLink to={link.link} className={activeStyle}>
            <p className='hover:scale-105 transition-transform duration-50'>
              {link.text}
            </p>
          </NavLink>
        </li>
      );
    });
  };

  return (
    <footer className='bg-white text-black text-left py-16 px-7 sm:p-16 text-lg'>
      <div className='flex flex-wrap'>
        <div className='flex flex-col gap-4 flex-wrap w-full sm:w-1/2 border-black sm:border-r-2'>
          <div className='flex items-center'>
            <img
              className='inline-block border-r-2 pr-3 w-24 border-black md:w-36'
              src={logoWhite}
              alt='AtoZ Marketing logo'
            />
            <img
              className='inline-block pl-3 w-28 md:w-40'
              src={longLogoWhite}
              alt='AtoZ Marketing logo'
            />
            <a
              className='inline-block'
              href='https://ccfsm04.na1.hs-sales-engage.com/Ctc/ZS+23284/cCfSM04/Jks2-6qcW69sMD-6lZ3q3W5sZD_j6KNB96W9kXLFs5g2FjyW1FyBVQ3ZJdpwW8Nh_T78q8K0PVk-23R8QfFXWW5rsD9C1Y1Pq2W7Cs-qv4nlpKhW6y-nJV2cNPmfN2M0Gkr6f9c0W1xy1Qc7wqsp6W3Vzy6Y5tj6QLW2qCdfn38SznJVSrW1n5pRKlvW47rqgk3345fHW3SnHfN4-HnvNW6WFMdp1th-F4W3tV8bM3z740-W4c2tg01XP55xN3bDrpMSzt-4W2VSkky5vt86Tf1q8kZv04'
              target='_blank'
              rel='noreferrer'
            >
              <img
                className='inline-block pl-3 w-24 border-black md:w-44'
                src={designRush}
                alt='Design Rush badge'
              />
            </a>
          </div>
          <div className='flex items-center'></div>
          <div className='flex items-center'>
            <img
              className='inline-block ml-2 mr-4 w-8 md:w-16'
              src={phoneIcon}
              alt='Phone icon'
            />
            <p className='text-3xl sm:text-3xl'>+61 493 323 312</p>
          </div>
          <div className='flex'>
            <a
              className='home-footer-instagram-icon'
              href='https://www.instagram.com/atoz.marketing.official'
              target='_blank'
              rel='noreferrer'
            >
              <img
                className='inline-block ml-2 hover:cursor-pointer w-8 md:w-16'
                width={100}
                src={instagramIcon}
                alt='instagram icon'
              />
            </a>
            <a
              className='home-footer-facebook-icon'
              href='https://www.facebook.com/atoz.marketing.official'
              target='_blank'
              rel='noreferrer'
            >
              <img
                className='inline-block mx-5 hover:cursor-pointer w-8 md:w-16'
                width={100}
                src={facebookIcon}
                alt='facebook icon'
              />
            </a>
            <a
              className='home-footer-whatsapp-icon'
              href='https://wa.me/614493323312'
              target='_blank'
              rel='noreferrer'
            >
              <img
                className='inline-block hover:cursor-pointer w-8 md:w-16'
                width={100}
                src={whatsappIcon}
                alt='whatsapp icon'
              />
            </a>
          </div>
        </div>
        <ul className='w-full sm:w-1/2 sm:pl-8 py-12 lg:py-12 text-3xl md:text-4xl'>
          <li className='font-bold pb-4'>Quick Links</li>
          {renderLinks()}
        </ul>
      </div>
      <p className='text-lg'>© 2024 A to Z Marketing. All Rights Reserved.</p>
    </footer>
  );
};

export default Footer;
